<template>
  <div class="">
    <div class="loader_mon" v-if="loading"></div>
    <div class="body monitoring" v-else>
      <div class="monitoring__filter-row">
        <div class="monitoring__filter-block">
          <select-filter
            class="monitoring__filter-block-item"
            :title="$t('monitoring.city')"
            name="city"
            :items="city_filters"
            @change="set_filter"
          ></select-filter>
          <select-filter
            class="monitoring__filter-block-item"
            :title="$t('monitoring.pos')"
            name="pos_name"
            :items="pos_filters"
            :disabled="pos_disabled"
            @change="set_filter"
          ></select-filter>
          <select-filter
            class="
              monitoring__filter-block-item
              monitoring__filter-block-item_before_btn
            "
            :title="$t('monitoring.badge_status')"
            name="badge_status"
            :items="status_list"
            @change="set_filter"
          ></select-filter>
          <select-filter
            class="
              monitoring__filter-block-item
              monitoring__filter-block-item_before_btn
            "
            :title="$t('monitoring.device_type')"
            name="device_type"
            :items="device_filters"
            @change="set_filter"
          ></select-filter>
          <span
            class="monitoring__filter-block-clear-btn"
            :class="{
              'monitoring__filter-block-clear-btn_disabled': disabled_clear_btn,
            }"
            @click="clear_filters"
          >
            {{ $t("monitoring.clear_filters") }}</span
          >
        </div>
        <appearance-filter
          :title="$t('monitoring.appearance')"
          name="appearance"
          class="select-filter_appearance-monitoring"
          :items="headers"
          @change="set_appearance_filter"
        ></appearance-filter>
      </div>
      <monitoring-table
        :header_items="filters_header"
        :items="badges"
        @change_sorted="change_sorted"
      ></monitoring-table>
    </div>
  </div>
</template>

<script>
import SelectFilter from "@/components/recurringComponents/selectFilter";
import AppearanceFilter from "@/components/recurringComponents/appearanceFilter";
import MonitoringTable from "@/components/components/monitoringTable";
import moment from "moment";

export default {
  name: "monitoring",
  components: { MonitoringTable, AppearanceFilter, SelectFilter },
  data() {
    return {
      headers: [
        {
          name: "city",
          title: this.$i18n.t("monitoring.headers.city"),
          sorted: 1,
          enable: false,
        },
        {
          name: "pos_name",
          title: this.$i18n.t("monitoring.headers.pos_name"),
          sorted: 0,
          enable: false,
        },
        {
          name: "device_type",
          title: this.$i18n.t("monitoring.headers.device_type"),
          sorted: 0,
          enable: false,
        },
        {
          name: "device_id",
          title: this.$i18n.t("monitoring.headers.device_id"),
          sorted: 0,
          enable: false,
        },
        {
          name: "device_status",
          title: this.$i18n.t("monitoring.headers.device_status"),
          sorted: 0,
          enable: false,
        },
        {
          name: "badge_id",
          title: this.$i18n.t("monitoring.headers.badge_id"),
          sorted: 0,
          enable: false,
        },
        {
          name: "uid",
          title: this.$i18n.t("monitoring.headers.uid"),
          sorted: 0,
          enable: false,
        },
        {
          name: "last_name",
          title: this.$i18n.t("monitoring.headers.last_name"),
          sorted: 0,
          enable: false,
        },
        {
          name: "first_name",
          title: this.$i18n.t("monitoring.headers.first_name"),
          sorted: 0,
          enable: false,
        },
        {
          name: "auth_time",
          title: this.$i18n.t("monitoring.headers.auth_time"),
          sorted: 0,
          enable: false,
        },
        // {
        //   name: "n_a_time",
        //   title: this.$i18n.t("monitoring.headers.n_a_time"),
        //   sorted: 0,
        //   enable: false,
        // },
        // {
        //   name: "active_time",
        //   title: this.$i18n.t("monitoring.headers.active_time"),
        //   sorted: 0,
        //   enable: false,
        // },
        // {
        //   name: "total_time",
        //   title: this.$i18n.t("monitoring.headers.total_time"),
        //   sorted: 0,
        //   enable: false,
        // },
        {
          name: "badge_status",
          title: this.$i18n.t("monitoring.headers.badge_status"),
          sorted: 0,
          enable: false,
        },
        {
          name: "fw_ver",
          title: this.$i18n.t("monitoring.headers.fw_ver"),
          sorted: 0,
          enable: false,
        },
      ],
      monitoring_polling: null,
    };
  },
  created() {
    if (this.selected_filters.city.length != 0) {
      this.selected_filters.city = [];
    };

    if (this.selected_filters.pos_name.length != 0) {
      this.selected_filters.pos_name = [];
    };

    this.$store.dispatch("load_monitoring");
    this.monitoring_polling_data();
  },
  mounted() {
    this.$store.dispatch("restore_filters");
  },
  watch: {
    "$i18n.locale"() {
      this.refresh_header_titles_locale();
    },
    pos_disabled() {
      if (this.selected_filters.city.length === 0) {
        this.selected_filters.pos_name = [];
      }
    },
    pos_filters(newValue) {
      this.selected_filters.pos_name = this.selected_filters.pos_name.filter(
        (x) => newValue.some((item) => item.value === x)
      );
    },
    selected_filters: {
      handler(newFilters) {
        this.$forceUpdate();
        console.log("Выбранные фильтры изменены:", newFilters);
      },
      deep: true,
    },
  },
  computed: {
    filters() {
      return this.$store.getters.FILTERS;
    },
    selected_filters() {
      return this.$store.getters.SELECTED_FILTERS;
    },
    pos_disabled() {
      return this.selected_filters.city.length === 0;
    },
    loading() {
      return this.$store.getters.LOADING_MONITORING;
    },
    status_list() {
      return [
        {
          text: this.$t("badge.status.recording"),
          value: "Recording",
        },
        {
          text: this.$t("badge.status.on_body"),
          value: "On-body",
        },
        {
          text: this.$t("badge.status.charging"),
          value: "Charging",
        },
        {
          text: this.$t("badge.status.waiting"),
          value: "Waiting",
        },
        {
          text: this.$t("badge.status.upload_error"),
          value: "Error",
        },
        {
          text: this.$t("badge.status.upload"),
          value: "Upload",
        },
        {
          text: this.$t("badge.status.offline"),
          value: "Hub is offline",
        },
        {
          text: this.$t("badge.status.application_offline"),
          value: "Application is offline",
        },
        {
          text: this.$t("badge.status.not_in_range"),
          value: "Not in range",
        },
      ].map((item) => {
        return { title: item.text, value: item.value };
      });
    },
    city_filters() {
      return Array.from(
        new Set(this.$store.getters.FILTERED_CITY.map((badge) => badge.city))
      ).map((item) => {
        return { title: item, value: item, selected: false };
      });
    },
    device_filters() {
      return [
        {
          value: "HUB",
          text: this.$t("device.type.hub"),
        },
        {
          value: "APP",
          text: this.$t("device.type.app"),
        },
      ].map((item) => {
        return { title: item.text, value: item.value, selected: false };
      });
    },
    pos_filters() {
      return Array.from(
        new Set(this.$store.getters.FILTERED_POS.map((badge) => badge.pos_name))
      ).map((item) => {
        return { title: item, value: item, selected: false };
      });
    },
    badges() {
      const badgesData = this.$store.getters.FILTERED_BADGES;
      const filteredBadges = badgesData.filter((badge) => {
        const cityMatch =
          this.selected_filters.city.length === 0 ||
          this.selected_filters.city.includes(badge.city);
        const posMatch =
          this.selected_filters.pos_name.length === 0 ||
          this.selected_filters.pos_name.includes(badge.pos_name);
        const deviceTypeMatch =
          this.selected_filters.device_type.length === 0 ||
          this.selected_filters.device_type.includes(badge.device_type);
        const badgeStatusMatch =
          this.selected_filters.badge_status.length === 0 ||
          this.selected_filters.badge_status.some((status) =>
            badge.badge_status.includes(status)
          );

        return cityMatch && posMatch && deviceTypeMatch && badgeStatusMatch;
      });
      console.log("Отфильтрованные данные:", filteredBadges);

      return filteredBadges.map((badge) => {
        let auth_time = "";
        if (badge.ts_auth != null) {
          auth_time =
            badge.ts_auth !== 0
              ? moment
                  .unix(Number(badge.ts_auth))
                  .utcOffset(Number(badge.tz))
                  .format("HH:mm:ss UTC ") + badge.tz
              : "";
        }

        let translate_statuses = [];
        if (badge.badge_status?.includes("Recording")) {
          translate_statuses.push(this.$t("badge.status.recording"));
        }
        if (badge.badge_status?.includes("Charging")) {
          translate_statuses.push(this.$t("badge.status.charging"));
        }
        if (badge.badge_status?.includes("On-body")) {
          translate_statuses.push(this.$t("badge.status.on_body"));
        }
        if (badge.badge_status?.includes("Upload")) {
          translate_statuses.push(this.$t("badge.status.upload"));
        }
        if (badge.badge_status?.includes("Waiting")) {
          translate_statuses.push(this.$t("badge.status.waiting"));
        }
        if (badge.badge_status?.includes("Error")) {
          translate_statuses.push(this.$t("badge.status.error"));
        }
        if (badge.badge_status?.includes("Rebooted")) {
          translate_statuses.push(this.$t("badge.status.rebooted"));
        }
        if (badge.badge_status?.includes("Hub is offline")) {
          translate_statuses.push(this.$t("badge.status.offline"));
        }
        if (badge.badge_status?.includes("Application is offline")) {
          translate_statuses.push(this.$t("badge.status.application_offline"));
        }
        if (badge.badge_status?.includes("Not in range")) {
          translate_statuses.push(this.$t("badge.status.not_in_range"));
        }

        let device_status = badge.device_status?.includes("Online")
          ? this.$t("device.status.online")
          : this.$t("device.status.offline");
        let device_type = badge.device_type?.includes("HUB")
          ? this.$t("device.type.hub")
          : this.$t("device.type.app");

        return {
          city: badge.city,
          pos_name: badge.pos_name,
          device_type: device_type,
          device_id: badge.device_id,
          device_status: device_status,
          badge_id: badge.badge_id,
          uid:
            badge.employee?.employee_id !== 0
              ? badge.employee?.employee_id
              : "",
          last_name: badge.employee?.name,
          first_name: badge.employee?.surname,
          auth_time: auth_time,
          // n_a_time:
          //   badge.ts_na != null
          //     ? new Date(badge.ts_na * 1000).toISOString().substr(11, 8)
          //     : "",
          // active_time:
          //   badge.ts_active != null
          //     ? new Date(badge.ts_active * 1000).toISOString().substr(11, 8)
          //     : "",
          // total_time:
          //   badge.ts_total != null
          //     ? new Date(badge.ts_total * 1000).toISOString().substr(11, 8)
          //     : "",
          badge_status: translate_statuses.join(", "),
          fw_ver: badge.device_fw_ver,
        };
      });
    },
    disabled_clear_btn() {
      console.log("Selected filters:", this.selected_filters);
      console.log("Filters:", this.filters);
      return (
        this.selected_filters.city.length === 0 &&
        this.selected_filters.pos_name.length === 0 &&
        this.selected_filters.device_type.length === 0 &&
        this.selected_filters.badge_status.length === 0
      );
    },

    filters_header() {
      let temp = [];
      for (let header of this.headers) {
        if (this.$store.getters.FILTERS["appearance"].includes(header.name)) {
          temp.push(header);
        }
      }
      return this.$store.getters.FILTERS["appearance"].length === 0
        ? this.headers
        : temp;
    },
  },
  methods: {
    monitoring_polling_data() {
      this.monitoring_polling = setInterval(() => {
        this.$store.dispatch("quiet_load_monitoring");
      }, 60000);
    },
    set_filter(filter) {
      console.log("Applying filter:", filter);
      this.$store.dispatch("set_filters", filter).then(() => {
        console.log("Filters after setting:", this.filters);
      });
    },

    set_appearance_filter() {
      this.$store.dispatch("apply_appearance_filters");
    },
    clear_filters() {
      if (!this.disabled_clear_btn) {
        console.log("Очистка фильтров...");
        this.$store.dispatch("clear_filters").then(() => {
          console.log("Фильтры очищены");
          this.$store.dispatch("quiet_load_monitoring");
        });
      }
    },
    apply_filters() {
      this.$emit("apply_filter");
      this.$store.dispatch("apply_filters");
    },
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.badges.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    refresh_header_titles_locale() {
      for (let item of this.headers) {
        item.title = this.$t(`monitoring.headers.${item.name}`);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.monitoring_polling);
  },
};
</script>

<style scoped></style>
